html{
  scroll-snap-type: y mandatory;
  scroll-behavior:smooth;
  @apply bg-zinc-900;
}
*{
  padding:0;
  margin:0;
  box-sizing: border-box;
  box-sizing: 0;
  font-family: "DM Sans", sans-serif;

}
body{
  /* background-color: #0c0c1d; */
  @apply bg-zinc-900;
  color: lightgray;

}
.dottedBackground{
    /* background-color: #0c0c1d; */
    background-color: #0c0c1d;
  
    /* --dot-bg: #161616; */
    --dot-bg: #000000;
    --dot-color: #848282;
    --dot-size: 1px;
    --dot-space: 30px;
    background:
      linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
      linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
      var(--dot-color);
}
a {
  text-decoration: none;
  color: inherit;
}
section{
  min-height: 100vh;
  scroll-snap-align: start;
  overflow: hidden;
  justify-content: center;
  align-items: center;

}

